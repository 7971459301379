<template>
  <div>
      <StandByC/>
  </div>
</template>

<script>
import StandByC from '../components/StandByC.vue'
export default {
  components: { StandByC },

}
</script>

<style>

</style>